import React from 'react';
import {
	Spinner,
	Card,
	CardBody,
	CardTitle,
	Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Alert,
	Breadcrumb, 
	BreadcrumbItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import request from "services/ApiService";
import AsyncSelect from 'react-select/lib/Async';

class UsuarioForm extends React.Component {

  _request = null;

  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.handlePerfil = this.handlePerfil.bind(this);
    this.handleProfissional = this.handleProfissional.bind(this);
    this.handleProfissionalChange = this.handleProfissionalChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.onDismiss = this.onDismiss.bind(this);

		this.state = {
      id: null,
      data: null,
      perfil: 'CADASTRO',
      perfis: [],
      isLoading: false,
      isError: false,
      popoverOpen: false,
      popoverTitle: null,
      popoverMessage: null
    }
  }

  async handleProfissional(inputValue) {
    if (!inputValue) {
        return [];
    }
    const response = await request(
        `/api/v1/profissional?nome=${inputValue}&size=10`
    );
    const json = await response.json();
    return json.content;
  }

  handleProfissionalChange = profissional => {
    this.setState({ profissional: profissional });
  };

  handleInputChange = ({target}) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
        [target.name]: value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { isLoading, id } = this.state;
    if(isLoading)
      return;
    
    this.setState({
      isLoading: true,
      popoverOpen: false,
      isError: false
    })

    let url = id ? `/api/v1/usuario/${id}` : `/api/v1/usuario`;
    let method  = id ? 'PUT' : 'POST';

    const { nome, usuario, perfil, senha, profissional } = this.state;
    this._request = request(url, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nome: nome,
            usuario: usuario,
            perfil: perfil,
            profissional: profissional,
            senha: senha
        })
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        id: data.id,
        nome: data.nome,
        usuario: data.usuario,
        perfil: data.perfil,
        profissional: data.profissional,
        created_by: data.created_by,
        created_date: data.created_date,
        last_modified_by: data.last_modified_by,
        last_modified_date: data.last_modified_date,
        isLoading: false,
        popoverOpen: true,
        popoverTitle: 'Sucesso',
        popoverMessage: 'Seus dados foram salvo com sucesso.',
			});
    })
    .catch(err =>
      this.setState({
        popoverOpen: true,
        popoverTitle: 'Erro',
        popoverMessage: 'Ocorreu um erro ao salvar os seus dados.',
        isLoading: false,
        isError: true
      })
		);
  }
  
  handleBack(){
    const query = new URLSearchParams(this.props.location.search);
		const page = query.get('page');
    this.props.history.push(`/usuario?page=${page}`);
  }

  onDismiss() {
    this.setState({
      popoverOpen: false
    });
  }

  handleLoad = async () => {
    const { id } = this.props.match.params
    if(!id) return;
    
    const { isLoading } = this.state;
    if(isLoading)
      return;

    this.setState({
      id: id,
      isLoading: true,
      isError: false
    })
		
    this._request = request(`/api/v1/usuario/${id}`)
    .then(response => response.json())
		.then(data => {
			this.setState({
        id: data.id,
        nome: data.nome,
        usuario: data.usuario,
        perfil: data.perfil,
        profissional: data.profissional,
        created_by: data.created_by,
        created_date: data.created_date,
        last_modified_by: data.last_modified_by,
        last_modified_date: data.last_modified_date,
				isLoading: false,
			});
		})
		.catch(err =>
			this.setState({ isError: true, isLoading: false })
		);
  }

  handlePerfil = async () => {
		this._request = request(`/api/v1/perfil`)
		.then(response => response.json())
		.then(data => {
			this.setState({
				perfis: data
			});
		});
	}

  componentDidMount() {
    this.handleLoad();
    this.handlePerfil();
	}

  render() {
    const { isLoading, id, nome, usuario, perfil, profissional, senha, perfis, created_by, created_date, last_modified_by, last_modified_date, isError } = this.state;
   
    return (
      <div>
        <Breadcrumb>
            <BreadcrumbItem><a href="#">Cadastro</a></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/usuario`}>Usuário</Link></BreadcrumbItem>
            {nome && (
              <BreadcrumbItem active>{nome}</BreadcrumbItem>
            )}
        </Breadcrumb>
        <Card>
          <CardBody>
            <div className="d-flex align-items-center">
              <div>
                <CardTitle>Dados do conteúdo</CardTitle>
              </div>
            </div>
            <Form method="POST" autoComplete="off" onSubmit={this.handleSubmit} >
                <Col>
                  <FormGroup>
                    <Label for="full_name">Nome </Label> <Label className="text-danger">*</Label>
                    <Input type="text" name="nome" id="nome" value={nome} required={true} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="usuario">Usuário </Label> <Label className="text-danger">*</Label>
                    <Input type="text" name="usuario" id="usuario" value={usuario} required={true} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                {!id && (
                  <Col>
                    <FormGroup>
                      <Label for="senha">Senha </Label> <Label className="text-danger">*</Label>
                      <Input type="password" name="senha" id="senha" value={senha} required={true} onChange={this.handleInputChange}/>
                    </FormGroup>
                  </Col>
                )}
                <Col xs="auto" sm="3" lg="2">
                  <FormGroup>
                    <Label for="perfil">Perfil </Label> <Label className="text-danger">*</Label>
                    <Input type="select" name="perfil" id="perfil" value={perfil} required={true} onChange={this.handleInputChange} disabled={isLoading}>
                    {perfis.map(row => {
                      return (
                        <option value={row.valor}>{row.nome}</option>
                      );
                    })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
									<FormGroup>
										<Label for="profissional">Profissional </Label> 
										<AsyncSelect
											value={profissional} 
											loadOptions={this.handleProfissional}
											getOptionValue={(option) => option.id}
											getOptionLabel={(option) => `${option.id} - ${option.nome}`}
											noOptionsMessage={(value) => (value.inputValue.length > 0 ?`Não encontrado: ${value.inputValue}` : 'Digite o nome do profissiional')}
											placeholder={''}
											onChange={this.handleProfissionalChange}
											required={true}
										/>
									</FormGroup>
								</Col>
                <Row form>
                  <FormGroup>
                    <Button type="button" color="light" onClick={this.handleBack} className="ml-3 mb-2 mt-2">
                      Voltar
                    </Button>
                  </FormGroup>
                  <FormGroup>
                    <Button id="save" type="submit" disabled={isLoading} color="success" className="ml-3 mb-2 mt-2">
                      Salvar alterações
                    </Button>
                  </FormGroup>
                </Row>
              </Form>
              <Alert color={isError ? "danger" : "success"} isOpen={this.state.popoverOpen} toggle={this.onDismiss}>
                {this.state.popoverMessage}
              </Alert>
              {isLoading && (
                <div style={{textAlign: 'center'}}>
                  <div style={{textAlign: 'center'}}> 
                    <Spinner type="grow" color="primary" />
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="success" />
                    <Spinner type="grow" color="danger" />
                    <Spinner type="grow" color="warning" />
                    <Spinner type="grow" color="info" />
                    <Spinner type="grow" color="dark" />
                  </div>
                  Carregando...
                </div>
              )}
          </CardBody>
          {id && (
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="text-muted">
                  <CardTitle>Dados da auditoria</CardTitle>
                </div>
              </div>
              <div className="text-muted">
                <Label>Criado por: {created_by} -  {new Date(created_date).toUTCString()}</Label>
              </div>
              <div className="text-muted">
                <Label>Atualizado por: {last_modified_by} - {new Date(last_modified_date).toUTCString()}</Label>
              </div>
            </CardBody>
          )}
        </Card>
      </div>
    );
  }
}
export default UsuarioForm;
