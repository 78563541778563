import React from 'react';
import {
	Spinner,
	Card,
	CardBody,
	CardTitle,
	Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Alert,
	Breadcrumb, 
  BreadcrumbItem,
  Modal,
	ModalHeader,
	ModalBody,
  ModalFooter,
  TabContent, 
  TabPane, 
  Nav, 
  NavItem, 
  NavLink,
  CardColumns,
  CardImg,
  CardSubtitle,
  CardText,
  Table,
  CustomInput
} from 'reactstrap';
import { Link } from 'react-router-dom';
import request from "services/ApiService";
import upload from "services/UploadService";
import AsyncSelect from 'react-select/lib/Async';

import { EditorState, Modifier, convertToRaw, convertFromRaw, convertToHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import classnames from 'classnames';

import base_bg from '../../assets/images/background/base_bg.jpg';

import { getToken } from "../../services/Auth";

class AtendimentoForm extends React.Component {

  _request = null;

  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleTipo = this.handleTipo.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.handlePaciente = this.handlePaciente.bind(this);
    this.handlePacienteChange = this.handlePacienteChange.bind(this);
    this.handleProfissional = this.handleProfissional.bind(this);
    this.handleProfissionalChange = this.handleProfissionalChange.bind(this);
    this.handleAnexo = this.handleAnexo.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.onChange3 = this.onChange3.bind(this);
    this.handleInserirFormula = this.handleInserirFormula.bind(this);
    this.handleFormula = this.handleFormula.bind(this);
    this.handleFormulaChange = this.handleFormulaChange.bind(this);
    this.handleImprimirFormula = this.handleImprimirFormula.bind(this);
    this.handleImprimirReceituario = this.handleImprimirReceituario.bind(this);
    this.toggle = this.toggle.bind(this);
    this.preview = this.preview.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleExame = this.handleExame.bind(this);
    this.handleExamChange = this.handleExamChange.bind(this);
    this.handleExamPrint = this.handleExamPrint.bind(this);

		this.state = {
      id: null,
      data: null,
      tipo: 'Cliente',
      tipos: [],
      anexos: [],
      exames: [],
      exams: [],
      selectedExams: [],
      isLoading: false,
      isError: false,
      popoverOpen: false,
      popoverTitle: null,
      popoverMessage: null,
      editorState: EditorState.createEmpty(),
      editorState2: EditorState.createEmpty(),
      editorState3: EditorState.createEmpty(),
      isFormulaOpen: false,
      isExamesOpen: false,
      activeTab: '1'
    }
  }

  handleInputChange = ({target}) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
        [target.name]: value
    });
  }

  async handlePaciente(inputValue) {
    if (!inputValue) {
        return [];
    }
    const response = await request(
        `/api/v1/paciente?nome=${inputValue}&size=10`
    );
    const json = await response.json();
    return json.content;
  }

  handlePacienteChange = paciente => {
    this.setState({ paciente: paciente });
  };

  async handleProfissional(inputValue) {
    if (!inputValue) {
        return [];
    }
    const response = await request(
        `/api/v1/profissional?nome=${inputValue}&size=10`
    );
    const json = await response.json();
    return json.content;
  }

  handleProfissionalChange = profissional => {
    this.setState({ profissional: profissional });
  };

  async handleFormula(inputValue) {
    if (!inputValue) {
        return [];
    }
    const { paciente } = this.state;
    const response = await request(
        `/api/v1/formula?nome=${inputValue}&sexo=${paciente.sexo}&size=10`
    );
    const json = await response.json();
    return json.content;
  }

  handleFormulaChange = formula => {
    this.setState({ formula: formula });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { isLoading, id } = this.state;
    if(isLoading)
      return;
    
    this.setState({
      isLoading: true,
      popoverOpen: false,
      isError: false
    })

    let url = id ? `/api/v1/atendimento/${id}` : `/api/v1/atendimento`;
    let method  = id ? 'PUT' : 'POST';

    const { tipo, paciente, profissional, editorState, observacao } = this.state;
    const rawContentState = convertToRaw(
      this.state.editorState.getCurrentContent()
    );
    const rawContentPrescricaoState = convertToRaw(
      this.state.editorState2.getCurrentContent()
    );
    const rawContentReceituarioState = convertToRaw(
      this.state.editorState3.getCurrentContent()
    );
    this._request = request(url, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tipo: tipo,
            paciente: paciente,
            profissional: profissional,
            ...( rawContentState ? { anamnese: JSON.stringify(rawContentState) } : null),
            ...( rawContentPrescricaoState ? { prescricao: JSON.stringify(rawContentPrescricaoState) } : null),
            ...( rawContentReceituarioState ? { receituario: JSON.stringify(rawContentReceituarioState) } : null),
            observacao: observacao,
        })
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        id: data.id,
        tipo: data.tipo,
        paciente: data.paciente,
        profissional: data.profissional,
        observacao: data.observacao,
        created_by: data.created_by,
        created_date: data.created_date,
        last_modified_by: data.last_modified_by,
        last_modified_date: data.last_modified_date,
        isLoading: false,
        popoverOpen: true,
        popoverTitle: 'Sucesso',
        popoverMessage: 'Seus dados foram salvo com sucesso.',
      });
    })
    .catch(err =>
      this.setState({
        popoverOpen: true,
        popoverTitle: 'Erro',
        popoverMessage: 'Ocorreu um erro ao salvar os seus dados.',
        isLoading: false,
        isError: true
      })
		);
  }
  
  handleBack(){
    const query = new URLSearchParams(this.props.location.search);
		const page = query.get('page');
    this.props.history.push(`/atendimento?page=${page}`);
  }

  onDismiss() {
    this.setState({
      popoverOpen: false
    });
  }

  handleLoad = async () => {
    const { id } = this.props.match.params
    if(!id) return;
    
    const { isLoading } = this.state;
    if(isLoading)
      return;

    this.setState({
      id: id,
      isLoading: true,
      isError: false
    })
		
    this._request = request(`/api/v1/atendimento/${id}`)
    .then(response => response.json())
		.then(data => {
			this.setState({
        id: data.id,
        tipo: data.tipo,
        paciente: data.paciente,
        profissional: data.profissional,
        observacao: data.observacao,
        created_by: data.created_by,
        created_date: data.created_date,
        last_modified_by: data.last_modified_by,
        last_modified_date: data.last_modified_date,
				isLoading: false,
      });
      const editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(data.anamnese))
      );
      const editorState2 = EditorState.createWithContent(
        convertFromRaw(JSON.parse(data.prescricao))
      );
      const editorState3 = EditorState.createWithContent(
        convertFromRaw(JSON.parse(data.receituario))
      );
      this.setState({
        editorState: editorState,
        editorState2: editorState2,
        editorState3: editorState3
      });
      this.handleAnexo();
		})
		.catch(err =>
			this.setState({ isError: true, isLoading: false })
    );
    
    this._request = request(`/api/v1/atendimento-exame?atendimento=${id}&size=999`)
		.then(response => response.json())
		.then(data => {
			this.setState({
				exames: data.content
			});
		});
  }

  handleTipo = async () => {
		this._request = request(`/api/v1/tipo`)
		.then(response => response.json())
		.then(data => {
			this.setState({
				tipos: data
			});
		});
  }

  handleExame = async () => {
    this._request = request(`/api/v1/exame?size=999999`)
		.then(response => response.json())
		.then(data => {
			this.setState({
				exams: data.content
			});
		});
  }

  handleAnexo = async () => {
    const { id } = this.state;
		this._request = request(`/api/v1/atendimento-anexo?atendimento=${id}`)
		.then(response => response.json())
		.then(data => {
			this.setState({
				anexos: data.content
			});
		});
  }

  handleUploadFile = (event) => {
    const { id } = this.state;
    const data = new FormData();
    data.append('file', event.target.files[0]);
    this._request = upload(`/api/v1/atendimento-anexo/upload?atendimentoId=${id}`, {
      method: 'POST',
      body: data
  })
  .then(response => response.json())
  .then(data => {
    this.handleAnexo();
  })
  .catch(err =>
    this.setState({
      popoverOpen: true,
      popoverTitle: 'Erro',
      popoverMessage: 'Ocorreu um erro ao salvar os seus dados.',
      isLoading: false,
      isError: true
    })
  );
  }
  
  onChange = (editorState) => {
    this.setState({
      editorState
    })
  }

  onChange2 = (editorState2) => {
    this.setState({
      editorState2
    })
  }

  onChange3 = (editorState3) => {
    this.setState({
      editorState3
    })
  }

  handleImprimirFormula() {
    let html = draftToHtml(convertToRaw(this.state.editorState2.getCurrentContent()));
    const { id, paciente, profissional } = this.state;
    //let header = `<span> Atendimento: ${new String(id).padStart(10, '0')}</span><br/>`;
    let header = `<span> Paciente: ${paciente.nome}</span><br/>`;
    //header += `<span> Sexo: ${paciente.sexo}</span><br/>`;
    //header += `<span> Data de nascimento: ${paciente.data_nascimento}</span><br/>`;
    header += `<b><h3> Receituário:</h3></b>`;
    let footer = `<style>body {margin: 5px;padding: 0; white-space: pre; color: #3e5569;font-family: "Nunito Sans", sans-serif;font-size: 14px; width: 794px; height: 1115px; background: url('${profissional.especialidade.toLowerCase()}_bg.jpg') no-repeat; background-size: 21cm 29.7cm;}</style><p>&nbsp;</p><p style="text-align: center;">_______________________________<br />${profissional.nome}<br />${profissional.registro}</p>`;
    var w = window.open('', '', 'width=800,height=600,resizeable,scrollbars');
    w.document.write('<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>'+ header + html + footer);
    w.document.close();
    //w.print();
  }

  handleImprimirReceituario() {
    let html = draftToHtml(convertToRaw(this.state.editorState3.getCurrentContent()));
    const { id, paciente, profissional } = this.state;
    let header = `
    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><em><span style="font-size: 22pt; font-family: Calibri,sans-serif; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Receitu&aacute;rio Controle Especial</span></em></strong></p>
    <table style="border: none; border-collapse: collapse; width: 100%;">
    <tbody>
    <tr style="height: 9pt;">
    <td style="vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; width: 240.625px;">
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Identifica&ccedil;&atilde;o do Emitente</span></strong></p>
    </td>
    <td style="vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; width: 487.625px;">
    <p style="line-height: 1.2; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 8pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1&ordf; VIA - FARM&Aacute;CIA</span></strong></p>
    </td>
    </tr>
    <tr style="height: 47pt;">
    <td style="vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; width: 240.625px;">
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 16pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${profissional.nome}</span></strong></p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><em><span style="font-size: 12pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${profissional.especialidade}</span></em></p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><em><span style="font-size: 9pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">${profissional.registro}</span></em></p>
    </td>
    <td style="vertical-align: top; padding: 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; width: 487.625px;">
    <p style="line-height: 1.2; text-align: right; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 8pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2&ordf; VIA - PACIENTE</span></strong></p>
    </td>
    </tr>
    </tbody>
    </table><br/>`;
    //header += `<span> Atendimento: ${new String(id).padStart(10, '0')}</span><br/>`;
    header += `<span> Paciente: ${paciente.nome}</span><br/>`;
    //header += `<span> Sexo: ${paciente.sexo}</span><br/>`;
    let footer = `<style>body {margin: 5px;padding: 0; white-space: pre; color: #3e5569;font-family: "Nunito Sans", sans-serif;font-size: 14px; width: 794px; height: 1115px; background: url('${profissional.especialidade.toLowerCase()}_bg.jpg') no-repeat; background-size: 21cm 29.7cm;}</style><p>&nbsp;</p><p style="line-height: 1.295; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Data</span></strong><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">____/____/______</span></p>
    <table style="border: none; border-collapse: collapse;">
    <tbody>
    <tr style="height: 13pt;">
    <td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1.5pt;">
    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">IDENTIFICA&Ccedil;&Atilde;O DO COMPRADOR</span></strong></p>
    </td>
    <td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1.5pt;" rowspan="2">&nbsp;</td>
    <td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1.5pt;">
    <p style="line-height: 1.2; text-align: center; margin-top: 0pt; margin-bottom: 0pt;"><strong><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">IDENTIFICA&Ccedil;&Atilde;O DO FORNECEDOR</span></strong></p>
    </td>
    </tr>
    <tr style="height: 121pt;">
    <td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1.5pt;"><br />
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Nome:___________________________________</span></p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 10pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">_________________________________________</span><span style="font-size: 10pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><br /></span><span style="font-size: 10pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Ident.:____________________Org. Emisor:_____</span></p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">End.:_________________________________</span></p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">_____________________________________</span></p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Cidade:________________________UF:____</span></p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Telefone:_____________________________</span></p>
    </td>
    <td style="vertical-align: top; padding: 0pt 5.4pt 0pt 5.4pt; overflow: hidden; overflow-wrap: break-word; border: solid #000000 1.5pt;"><br /><br /><br /><br /><br /><br />
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">___________________________ &nbsp; ____/___/_____</span></p>
    <p style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: Calibri,sans-serif; ; background-color: transparent; font-weight: 400; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assinatura do Farmac&ecirc;utico&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Data</span></p>
    </td>
    </tr>
    </tbody>
    </table>`;
    var w = window.open('', '', 'width=800,height=600,resizeable,scrollbars');
    w.document.write('<br/><br/><br/><br/><br/><br/><br/>'+ header + html + footer);
    w.document.close();
    //w.print();
  }

  handleInserirFormula = async () => {
    const { formula } = this.state;
    const response = await request(
      `/api/v1/formula-componente?formula=${formula.id}&size=20`
    );
    const json = await response.json();
    const content = json.content;
    let text = `1 ${formula.apresentacao}\n`;
    content.forEach(function(formula){
      let nome = formula.componente.nome.padEnd(100, '-');
      let dosagem = formula.dosagem.padStart(10, ' ');
      let medida = formula.medida.nome.padEnd(10, ' ');
      text += `\t${nome} ${dosagem} ${medida}\n`;
    });
    if(formula.posologia) text += `\n\tPosologia: ${formula.posologia}`;
    text += `\n\n`;
    const editorState = this.state.editorState2;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const ncs = Modifier.insertText(contentState, selection, text);
    const es = EditorState.push(editorState, ncs, 'insert-fragment');
    this.setState({
      editorState2: es,
      isFormulaOpen: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentDidMount() {
    this.handleLoad();
    this.handleTipo();
  }
  
  preview(id) {
    var w = window.open(`/api/v1/atendimento-anexo/${id}?access_token=${getToken()}`, '', 'width=800,height=600,resizeable,scrollbars');
    w.document.close();
  }

  handleDelete = async () => {
		const { selected } = this.state;
        if(!selected) return;

		this._request = request(`/api/v1/atendimento-anexo/${selected.id}`, {
			method: 'DELETE'
		})
		.then(() => {
      this.setState({ isDelete: false });
		  this.handleAnexo();
		});
  }
  
  handleExamChange = ({target}) => {
    const { selectedExams } = this.state;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if(value) 
      selectedExams.push(target.name);
    else
      selectedExams.splice(selectedExams.indexOf(target.name), 1 );
  }

  handleExamPrint = async () => {
    const { id, paciente, profissional, exams, selectedExams } = this.state;

    let header = `<span> Paciente: ${paciente.nome}</span><br/>`;
    header += `<span> Atendimento: ${new String(id).padStart(10, '0')}</span><br/>`;
    header += `<b><h3> Solicitação de exames:</h3></b>`;
    let count = 1;
    exams.filter(m => {
      return selectedExams.indexOf(m.id.toString()) != -1;
    }).forEach(p => {
      header += `<span> <b>${count++} - ${p.nome}</b></span><br>`;
    });

    let footer = `<style>body {margin: 5px;padding: 0; white-space: pre; color: #3e5569;font-family: "Nunito Sans", sans-serif;font-size: 14px; width: 794px; height: 1115px; background: url('${profissional.especialidade.toLowerCase()}_bg.jpg') no-repeat; background-size: 21cm 29.7cm;}</style><p>&nbsp;</p><p style="text-align: center;">_______________________________<br />${profissional.nome}<br />${profissional.registro}</p>`;
    var w = window.open('', '', 'width=800,height=600,resizeable,scrollbars');
    w.document.write('<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>'+ header + footer);
    w.document.close();
  }

  render() {
    const { isLoading, id, tipo, tipos, paciente, profissional, observacao, formula, created_by, created_date, last_modified_by, last_modified_date, anexos, selected, exames, exams, isError } = this.state;
   
    return (
      <div>
        <Breadcrumb>
            <BreadcrumbItem><a href="#">Cadastro</a></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/atendimento`}>Atendimento</Link></BreadcrumbItem>
            {paciente && (
              <BreadcrumbItem active>{new String(id).padStart(10, '0')} - {paciente.nome}</BreadcrumbItem>
            )}
        </Breadcrumb>
        <Card>
          <CardBody>
            <div className="d-flex align-items-center">
              <div>
                <CardTitle>Dados do conteúdo</CardTitle>
              </div>
            </div>
            <Form method="POST" autoComplete="off" onSubmit={this.handleSubmit} >
                <Col xs="auto" sm="3" lg="2">
                  <FormGroup>
                    <Label for="tipo">Tipo </Label> <Label className="text-danger">*</Label>
                    <Input type="select" name="tipo" id="tipo" value={tipo} required={true} onChange={this.handleInputChange} disabled={isLoading || id}>
                    {tipos.map(row => {
                      return (
                        <option key={row.valor} value={row.valor}>{row.nome}</option>
                      );
                    })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
									<FormGroup>
										<Label for="paciente">Paciente </Label> <Label className="text-danger">*</Label>
										<AsyncSelect
											value={paciente} 
											loadOptions={this.handlePaciente}
											getOptionValue={(option) => option.id}
											getOptionLabel={(option) => `${option.id} - ${option.nome}`}
											noOptionsMessage={(value) => (value.inputValue.length > 0 ?`Não encontrado: ${value.inputValue}` : 'Digite o nome do paciente')}
											placeholder={''}
											onChange={this.handlePacienteChange}
											required={true}
										/>
									</FormGroup>
								</Col>
                <Col>
									<FormGroup>
										<Label for="profissional">Profissional </Label> <Label className="text-danger">*</Label>
										<AsyncSelect
											value={profissional} 
											loadOptions={this.handleProfissional}
											getOptionValue={(option) => option.id}
											getOptionLabel={(option) => `${option.id} - ${option.nome}`}
											noOptionsMessage={(value) => (value.inputValue.length > 0 ?`Não encontrado: ${value.inputValue}` : 'Digite o nome do profissiional')}
											placeholder={''}
											onChange={this.handleProfissionalChange}
											required={true}
										/>
									</FormGroup>
								</Col>
                <Col>
                  <FormGroup>
                    <Label for="observacao">Observacão </Label>
                    <Input type="text" name="observacao" id="observacao" value={observacao} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Nav tabs>
                  <NavItem>
                    <NavLink onClick={() => { this.toggle('1'); }}
                      className={classnames({ active: this.state.activeTab === '1' })}>
                      Anamnese
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => { this.toggle('2'); }}
                      className={classnames({ active: this.state.activeTab === '2' })}>
                      Receituário Simples
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => { this.toggle('3'); }} 
                      className={classnames({ active: this.state.activeTab === '3' })}>
                      Receituário Especial
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => { this.toggle('4'); }} 
                      className={classnames({ active: this.state.activeTab === '4' })}>
                      Imagens/Anexos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => { this.toggle('5'); }} 
                      className={classnames({ active: this.state.activeTab === '5' })}>
                      Exames
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Col>
                      <FormGroup>
                        <Editor
                          editorState={this.state.editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onChange}
                        />
                      </FormGroup>
                    </Col>
                  </TabPane>
                  <TabPane tabId="2">
                    <Col>
                      <FormGroup>
                        <Editor
                          editorState={this.state.editorState2}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onChange2}
                        />
                        <Button size="sm" color="primary" type="button" onClick={() => this.setState({ isFormulaOpen: true })}>Pesquisar formulas</Button>
                        <Button size="sm" color="light" type="button" onClick={() => this.handleImprimirFormula()}>Imprimir</Button>
                      </FormGroup>
                    </Col>
                  </TabPane>
                  <TabPane tabId="3">
                    <Col>
                      <FormGroup>
                        <Editor
                          editorState={this.state.editorState3}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onChange3}
                        />
                        <Button size="sm" color="light" type="button" onClick={() => this.handleImprimirReceituario()}>Imprimir</Button>
                      </FormGroup>
                    </Col>
                  </TabPane>
                  <TabPane tabId="4">
                    <Col>
                      <FormGroup>
                        <Label>Anexo </Label>
                        <Input type="file" required={false} onChange={this.handleUploadFile} disabled={isLoading}/>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <CardColumns>
                          {anexos.map(row => {
                            return (
                              <Card>
                                <CardBody>
                                  <CardTitle>{row.fileName}</CardTitle>
                                  <CardSubtitle>{row.created_by} - {new Date(row.created_date).toUTCString()}</CardSubtitle>
                                  <Button size="sm" onClick={() => this.preview(row.id)}>Visualizar</Button>
                                  <Button size="sm" color="danger" onClick={() => this.setState({ isDelete: true, selected: row })}>Remover</Button>
                                </CardBody>
                              </Card>
                              );
                            })}
                        </CardColumns>
                      </FormGroup>
                    </Col>
                    <Modal isOpen={this.state.isDelete} centered={true} fade={false}>
                      <ModalHeader>Exclusão de dados</ModalHeader>
                      <ModalBody>
                        <b>Anexo: </b> {selected ? selected.fileName : ""}<br />
                        <b>Criado por: </b> {selected ? selected.created_by: ""} - {selected ? new Date(selected.created_date).toUTCString(): ""}<br />
                      </ModalBody>
                      <ModalFooter>
                        <Button color="danger" onClick={() => this.handleDelete()}>Excluir</Button>{' '}
                        <Button color="secondary" onClick={() => this.setState({ isDelete: false })}>Cancelar</Button>
                      </ModalFooter>
                    </Modal>
                  </TabPane>
                  <TabPane tabId="5">
                    <FormGroup className="mt-2">
                      <Button color="warning" onClick={() => this.setState({ isExamesOpen: true }, this.handleExame)}>Solicitar exames</Button>{' '}
                    </FormGroup>
                    <Table className="no-wrap v-middle" responsive hover striped>
                      <tbody>
                        {exames.map(row => {
                          return (
                            <tr key={row.id}>
                              <td>
                                <div className="d-flex no-block align-items-center">
                                  <div>
                                    <h5 className="mb-0 font-16 font-medium">{row.exame.nome}</h5>
                                    {( paciente &&
                                      <span className="text-muted"><b>Referencia:</b> {paciente.sexo == 'Masculino' ? row.exame.homem : row.exame.mulher} {paciente.sexo == 'Masculino' ? row.exame.medida.sigla : row.exame.medida2.sigla}</span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  {( paciente &&
                                    <span><span className="text-muted">Resultado: </span> {row.resultado} {paciente.sexo == 'Masculino' ? row.exame.medida.sigla : row.exame.medida2.sigla}</span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <Modal isOpen={this.state.isExamesOpen} centered={true} fade={false}>
                      <ModalHeader>Solicitação de exames</ModalHeader>
                      <ModalBody>
                        <Table className="no-wrap v-middle" responsive hover striped>
                          <tbody>
                            {exams.map(row => {
                              return (
                                <tr key={row.id}>
                                  <td>
                                    <div className="d-flex no-block align-items-center">
                                      <div>
                                        <h5 className="mb-0 font-16 font-medium">{row.nome}</h5>
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{width: 10}}>
                                    <CustomInput type="switch" id={row.id} name={row.id} onChange={this.handleExamChange} />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="success" onClick={() => this.handleExamPrint()}>Imprimir</Button>{' '}
                        <Button color="secondary" onClick={() => this.setState({ isExamesOpen: false })}>Cancelar</Button>
                      </ModalFooter>
                    </Modal>
                  </TabPane>
                </TabContent>
                <Row form>
                  <FormGroup>
                    <Button type="button" color="light" onClick={this.handleBack} className="ml-3 mb-2 mt-2">
                      Voltar
                    </Button>
                  </FormGroup>
                  <FormGroup>
                    <Button id="save" type="submit" disabled={isLoading} color="success" className="ml-3 mb-2 mt-2">
                      Salvar alterações
                    </Button>
                  </FormGroup>
                </Row>
              </Form>
              <Alert color={isError ? "danger" : "success"} isOpen={this.state.popoverOpen} toggle={this.onDismiss}>
                {this.state.popoverMessage}
              </Alert>
              {isLoading && (
                <div style={{textAlign: 'center'}}>
                  <div style={{textAlign: 'center'}}> 
                    <Spinner type="grow" color="primary" />
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="success" />
                    <Spinner type="grow" color="danger" />
                    <Spinner type="grow" color="warning" />
                    <Spinner type="grow" color="info" />
                    <Spinner type="grow" color="dark" />
                  </div>
                  Carregando...
                </div>
              )}
          </CardBody>
          {id && (
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="text-muted">
                  <CardTitle>Dados da auditoria</CardTitle>
                </div>
              </div>
              <div className="text-muted">
                <Label>Criado por: {created_by} -  {new Date(created_date).toUTCString()}</Label>
              </div>
              <div className="text-muted">
                <Label>Atualizado por: {last_modified_by} - {new Date(last_modified_date).toUTCString()}</Label>
              </div>
            </CardBody>
          )}
        </Card>
        <Modal isOpen={this.state.isFormulaOpen} centered={true} fade={false}>
							<ModalHeader>Inclusão de formula</ModalHeader>
							<ModalBody>
                <AsyncSelect
											value={formula} 
											loadOptions={this.handleFormula}
											getOptionValue={(option) => option.id}
											getOptionLabel={(option) => `${option.id} - ${option.nome}`}
											noOptionsMessage={(value) => (value.inputValue.length > 0 ?`Não encontrado: ${value.inputValue}` : 'Digite o nome da formula')}
											placeholder={''}
											onChange={this.handleFormulaChange}
											required={true}
										/>
							</ModalBody>
							<ModalFooter>
								<Button color="primary" onClick={() => this.handleInserirFormula()}>Inserir</Button>{' '}
								<Button color="secondary" onClick={() => this.setState({ isFormulaOpen: false })}>Cancelar</Button>
							</ModalFooter>
						</Modal>
      </div>
    );
  }
}
export default AtendimentoForm;
