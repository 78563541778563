import { logout, getToken } from "../services/Auth";

export default async function request(url, options = {}) {
  let token = await getToken();
  options.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...( token ? { 'Authorization': 'Bearer ' + token } : null)
  };
  let promise = new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status === 401) {
          logout();
        }
        if (response.ok) {
          return resolve(response);
        }
        return reject(response);
      })
      .catch((error) => reject({
        networkError: error.message,
      }));
  });
  return promise;
}