import React from "react";
import {
	Spinner,
	Card,
	CardBody,
	CardTitle,
	Button,
	Table,
	Alert,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	UncontrolledTooltip,
	Breadcrumb, 
	BreadcrumbItem,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Badge,
	Row,
	Col,
	Label,
	CustomInput
} from 'reactstrap';
import { Link } from 'react-router-dom';
import request from "services/ApiService";
import PaginationComponent from "../../components/pagination";
import AsyncSelect from 'react-select/lib/Async';


class PacienteExameList extends React.Component {

	_request = null;

	constructor(props) {
		super(props);
		this.handleLoad = this.handleLoad.bind(this);
		this.handleMedida = this.handleMedida.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleClearSearch = this.handleClearSearch.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleComponente = this.handleComponente.bind(this);
		this.handleComponenteChange = this.handleComponenteChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);

		this.state = {
            isLoading: false,
			isSearch: false,
			isDelete: false,
			isError: false,
			text: '',
			paciente: {},
			rows: [],
			medidas: [],
            pageNumber: 1,
            totalElements: 0,
            size: 6
        }
	}

	handleSubmit = (event) => {
		event.preventDefault();
	
		const { isLoading } = this.state;
		if(isLoading)
		  return;
		
		this.setState({
		  isLoading: true,
		  popoverOpen: false,
		  isError: false
		})
	
		const { formula, componente, medida, dosagem } = this.state;
		this._request = request(`/api/v1/formula-componente`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				formula: formula,
				medida: medida,
				dosagem: dosagem,
				componente: componente
			})
		})
		.then(response => response.json())
		.then(data => {
			this.setState({
				isLoading: false,
				isError: false
			})
		  	this.handleLoad();
		})
		.catch(err =>
		  this.setState({
			popoverOpen: true,
			popoverTitle: 'Erro',
			popoverMessage: 'Ocorreu um erro ao salvar os seus dados.',
			isLoading: false,
			isError: true
		  })
		);
	  }

	async handleComponente(inputValue) {
        if (!inputValue) {
            return [];
        }
        const response = await request(
            `/api/v1/componente?nome=${inputValue}&size=10`
        );
        const json = await response.json();
        return json.content;
	}
	
	handleComponenteChange = componente => {
        this.setState({ componente: componente });
	};
	
	handleMedidaChange = medida => {
        this.setState({ medida: medida });
    };

	handleLoad = async (pageNumber = this.state.pageNumber) => {
		const { isLoading, size, text } = this.state;
        if(isLoading)
            return;

        await this.setState({
			isSearch: false,
			isLoading: true,
			isDelete: false,
			isError: false
        })

        if(text.length > 0) {
            this.setState({
                isSearch: true
            })
		}
		
		this.props.history.push(`?page=${pageNumber}`);

		const { id } = this.props.match.params
		
		this._request = request(`/api/v1/exame?size=999999`)
		.then(response => response.json())
		.then(data => {
			if(data.content.length === 0 && 
				pageNumber > 1) {
					this.handleClearSearch();
			}
			this.setState({
				pageNumber: pageNumber++,
				rows: data.content,
				totalElements: data.totalElements,
				isLoading: false
			});
		})
		.catch(err =>
			this.setState({ isError: true, isLoading: false })
		);

		this._request = request(`/api/v1/paciente/${id}`)
			.then(response => response.json())
			.then(data => {
				this.setState({
					paciente: data
				});
			});
	}

	async handleMedida(inputValue) {
        if (!inputValue) {
            return [];
        }
        const response = await request(
            `/api/v1/medida?nome=${inputValue}&size=10`
        );
        const json = await response.json();
        return json.content;
	}

	handleSearch = async () => {
		this.handleLoad(1);
	}

	handleClearSearch = async () => {
		await this.setState({
			text: '',
			pageNumber: 1,
			isSearch: false
		})
		this.handleLoad(1);
	}

	handleDelete = async () => {
		const { selected } = this.state;
        if(!selected) return;

		this._request = request(`/api/v1/formula-componente/${selected.id}`, {
			method: 'DELETE'
		})
		.then(() => {
		  this.handleLoad();
		});
	}

	handleInputChange = ({target}) => {
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({
			[target.name]: value
		});
	  }

	componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		const page = query.get('page');
		this.handleLoad(page || 1);
		this.handleMedida();
	}

	render() {

		const { isLoading, rows, paciente, componente,medida, dosagem, error, totalElements, pageNumber, size, text, isSearch, selected } = this.state;

		return (
			<div>
				<Breadcrumb>
					<BreadcrumbItem><a href="#">Cadastro</a></BreadcrumbItem>
					<BreadcrumbItem><Link to={`/paciente`}>Paciente</Link></BreadcrumbItem>
					<BreadcrumbItem><Link to={`/paciente-edit/${paciente.id}`}>{paciente.nome}</Link></BreadcrumbItem>
					<BreadcrumbItem active>Exame</BreadcrumbItem>
				</Breadcrumb>
				<Card>
					<CardBody>
						<div className="d-flex align-items-center">
							<div>
								<CardTitle>Lista de exames</CardTitle>
							</div>
							<div className="ml-auto d-flex no-block align-items-center">
								<div className="dl">
									<Button color="secondary" onClick={() => this.handleLoad()} className="ml-3 mb-2 mt-2">
										Imprimir
									</Button>
								</div>
							</div>
						</div>
						{isLoading && (
							<div style={{textAlign: 'center'}}>
								<div style={{textAlign: 'center'}}> 
									<Spinner type="grow" color="primary" />
									<Spinner type="grow" color="secondary" />
									<Spinner type="grow" color="success" />
									<Spinner type="grow" color="danger" />
									<Spinner type="grow" color="warning" />
									<Spinner type="grow" color="info" />
									<Spinner type="grow" color="dark" />
								</div>
								Carregando...
							</div>
						)}
						{!isLoading && error && (
							<Alert color = "danger" style={{textAlign: 'center'}}>
								Ocorreu um erro ao localizar os dados. Clique aqui para tentar mais uma vez.
							</Alert>
						)}
						{ !isLoading && rows.length > 0 && (
							<Table className="no-wrap v-middle" responsive hover striped>
								<tbody>
									{rows.map(row => {
										return (
											<tr key={row.id}>
												<td>
													<div className="d-flex no-block align-items-center">
														<div>
															<h5 className="mb-0 font-16 font-medium">{row.nome}</h5>
															<span className="text-muted">{row.sexo}</span>
														</div>
													</div>
												</td>
												<td style={{width: 10}}>
													<CustomInput type="switch" id={`exame_${row.id}`} name={`exame_${row.id}`} />
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						)}
						<Modal isOpen={this.state.isDelete} centered={true} fade={false}>
							<ModalHeader>Exclusão de dados</ModalHeader>
							<ModalBody>
								<b>Nome: </b> {selected ? selected.componente.nome : ""}<br />
								<b>Criado por: </b> {selected ? selected.created_by: ""} - {selected ? new Date(selected.created_date).toUTCString(): ""}<br />
							</ModalBody>
							<ModalFooter>
								<Button color="danger" onClick={() => this.handleDelete()}>Excluir</Button>{' '}
								<Button color="secondary" onClick={() => this.setState({ isDelete: false })}>Cancelar</Button>
							</ModalFooter>
						</Modal>
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default PacienteExameList;
