import React from "react";
import {
	Spinner,
	Card,
	CardBody,
	CardTitle,
	Button,
	Table,
	Alert,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	UncontrolledTooltip,
	Breadcrumb, 
	BreadcrumbItem,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Badge,
	Row,
	Col,
	Label
} from 'reactstrap';
import { Link } from 'react-router-dom';
import request from "services/ApiService";
import PaginationComponent from "../../components/pagination";
import AsyncSelect from 'react-select/lib/Async';

class AtendimentoExameList extends React.Component {

	_request = null;

	constructor(props) {
		super(props);
		this.handleLoad = this.handleLoad.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);

		this.state = {
            isLoading: false,
			isSearch: false,
			isDelete: false,
			isError: false,
			text: '',
			formula: {},
			rows: [],
			medidas: [],
            pageNumber: 1,
            totalElements: 0,
            size: 20
        }
	}

	handleInputChange = ({target}) => {
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({
			[target.name]: value
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
	
		const { isLoading } = this.state;
		if(isLoading)
		  return;
		
		this.setState({
		  isLoading: true,
		  popoverOpen: false,
		  isError: false
		})
	
		const { atendimento, exame, resultado } = this.state;
		this._request = request(`/api/v1/atendimento-exame`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				atendimento: atendimento,
				exame: exame,
				resultado: resultado
			})
		})
		.then(response => response.json())
		.then(data => {
			this.setState({
				isLoading: false,
				isError: false
			})
		  	this.handleLoad();
		})
		.catch(err =>
		  this.setState({
			popoverOpen: true,
			popoverTitle: 'Erro',
			popoverMessage: 'Ocorreu um erro ao salvar os seus dados.',
			isLoading: false,
			isError: true
		  })
		);
	  }

	async handleExame(inputValue) {
        if (!inputValue) {
            return [];
        }
        const response = await request(
            `/api/v1/exame?nome=${inputValue}&size=10`
        );
        const json = await response.json();
        return json.content;
	}
	
	handleExameChange = exame => {
        this.setState({ exame: exame });
	};

	handleLoad = async (pageNumber = this.state.pageNumber) => {
		const { isLoading, size, text } = this.state;
        if(isLoading)
            return;

        await this.setState({
			isSearch: false,
			isLoading: true,
			isDelete: false,
			isError: false
        })

        if(text.length > 0) {
            this.setState({
                isSearch: true
            })
		}
		
		this.props.history.push(`?page=${pageNumber}`);

		const { id } = this.props.match.params
		
		this._request = request(`/api/v1/atendimento-exame?atendimento=${id}&page=${pageNumber}&size=${size}`)
		.then(response => response.json())
		.then(data => {
			if(data.content.length === 0 && 
				pageNumber > 1) {
					this.handleClearSearch();
			}
			this.setState({
				pageNumber: pageNumber++,
				rows: data.content,
				totalElements: data.totalElements,
				isLoading: false
			});
		})
		.catch(err =>
			this.setState({ isError: true, isLoading: false })
		);

		this._request = request(`/api/v1/atendimento/${id}`)
			.then(response => response.json())
			.then(data => {
				this.setState({
					atendimento: data
				});
			});
	}

	handleDelete = async () => {
		const { selected } = this.state;
        if(!selected) return;

		this._request = request(`/api/v1/atendimento-exame/${selected.id}`, {
			method: 'DELETE'
		})
		.then(() => {
		  this.handleLoad();
		});
	}

	componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		const page = query.get('page');
		this.handleLoad(page || 1);
	}

	render() {

		const { isLoading, error, atendimento, exame, resultado, rows, totalElements, pageNumber, size, selected } = this.state;

		return (
			<div>
				<Breadcrumb>
					<BreadcrumbItem><a href="#">Cadastro</a></BreadcrumbItem>
					<BreadcrumbItem><Link to={`/atendimento`}>Atendimento</Link></BreadcrumbItem>
					{atendimento &&(
						<BreadcrumbItem><Link to={`/atendimento-edit/${atendimento.id}`}>{new String(atendimento.id).padStart(10, '0')} - {atendimento.paciente.nome}</Link></BreadcrumbItem>
					)}
					<BreadcrumbItem active>Exame</BreadcrumbItem>
				</Breadcrumb>
				<Card>
					<CardBody>
						<Form method="POST" autoComplete="off" onSubmit={this.handleSubmit} >
							<Row form>
								<Col sm={6} md={8}>
									<FormGroup>
										<Label for="exame">Exame </Label> <Label className="text-danger">*</Label>
										<AsyncSelect
											value={exame} 
											loadOptions={this.handleExame}
											getOptionValue={(option) => option.id}
											getOptionLabel={(option) => `${option.id} - ${option.nome}`}
											noOptionsMessage={(value) => (value.inputValue.length > 0 ?`Não encontrado: ${value.inputValue}` : 'Digite o nome do exame')}
											placeholder={''}
											onChange={this.handleExameChange}
											required={true}
										/>
									</FormGroup>
								</Col>
								<Col xs={2} sm={2}>
									<FormGroup>
										<Label for="resultado">Resultado </Label> <Label className="text-danger">*</Label>
										<Input type="text" name="resultado" id="resultado" value={resultado} required={true} onChange={this.handleInputChange} disabled={isLoading}/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup className="mt-4">
										<Button color="secondary" type="submit">
											Adicionar
										</Button>
									</FormGroup>
								</Col>
							</Row>
						</Form>
						{isLoading && (
							<div style={{textAlign: 'center'}}>
								<div style={{textAlign: 'center'}}> 
									<Spinner type="grow" color="primary" />
									<Spinner type="grow" color="secondary" />
									<Spinner type="grow" color="success" />
									<Spinner type="grow" color="danger" />
									<Spinner type="grow" color="warning" />
									<Spinner type="grow" color="info" />
									<Spinner type="grow" color="dark" />
								</div>
								Carregando...
							</div>
						)}
						{!isLoading && error && (
							<Alert color = "danger" style={{textAlign: 'center'}}>
								Ocorreu um erro ao localizar os dados. Clique aqui para tentar mais uma vez.
							</Alert>
						)}
						{ !isLoading && rows.length > 0 && (
							<Table className="no-wrap v-middle" responsive hover striped>
								<tbody>
									{rows.map(row => {
										return (
											<tr key={row.id}>
												<td>
													<div className="d-flex no-block align-items-center">
														<div>
															<h5 className="mb-0 font-16 font-medium">{row.exame.nome}</h5>
															{( atendimento &&
																<span className="text-muted"><b>Referencia:</b> {atendimento.paciente.sexo == 'Masculino' ? row.exame.homem : row.exame.mulher} {atendimento.paciente.sexo == 'Masculino' ? row.exame.medida.sigla : row.exame.medida2.sigla}</span>
															)}
														</div>
													</div>
												</td>
												<td>
													<div>
														{( atendimento &&
															<span><span className="text-muted">Resultado: </span> {row.resultado} {atendimento.paciente.sexo == 'Masculino' ? row.exame.medida.sigla : row.exame.medida2.sigla}</span>
														)}
													</div>
												</td>
												<td style={{width: 10}}>
													<InputGroupAddon addonType="prepend">
														<Button size="sm" id={`btnDelete_${row.id}`} color="danger" onClick={() => this.setState({ isDelete: true, selected: row })}><i className="fa fa-times"></i></Button>
														<UncontrolledTooltip placement="bottom" target={`btnDelete_${row.id}`}>
															Apagar registro
														</UncontrolledTooltip>
													</InputGroupAddon>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						)}
						<Modal isOpen={this.state.isDelete} centered={true} fade={false}>
							<ModalHeader>Exclusão de dados</ModalHeader>
							<ModalBody>
								<b>Nome: </b> {selected ? selected.exame.nome : ""}<br />
								<b>Criado por: </b> {selected ? selected.created_by: ""} - {selected ? new Date(selected.created_date).toUTCString(): ""}<br />
							</ModalBody>
							<ModalFooter>
								<Button color="danger" onClick={() => this.handleDelete()}>Excluir</Button>{' '}
								<Button color="secondary" onClick={() => this.setState({ isDelete: false })}>Cancelar</Button>
							</ModalFooter>
						</Modal>
						{ !isLoading && rows.length > 0 && (
							<PaginationComponent
								totalItems={totalElements}
								pageSize={size}
								onSelect={this.handleLoad}
								maxPaginationNumbers={9}
								activePage={pageNumber}
							/>
						)}
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default AtendimentoExameList;
