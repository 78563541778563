import React from 'react';
import {
	Spinner,
	Card,
	CardBody,
	CardTitle,
	Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Alert,
	Breadcrumb, 
	BreadcrumbItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import request from "services/ApiService";
import AsyncSelect from 'react-select/lib/Async';

class PacienteForm extends React.Component {

  _request = null;

  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleSexo = this.handleSexo.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
    this.handleCidade = this.handleCidade.bind(this);
    this.handleCidadeChange = this.handleCidadeChange.bind(this);

		this.state = {
      id: null,
      data: null,
      sexo: 'Masculino',
      sexos: [],
      isLoading: false,
      isError: false,
      popoverOpen: false,
      popoverTitle: null,
      popoverMessage: null
    }
  }

  handleInputChange = ({target}) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
        [target.name]: value
    });
  }

  handleBirthDateChange = data_nascimento => {
    this.setState({
        data_nascimento: data_nascimento
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { isLoading, id } = this.state;
    if(isLoading)
      return;
    
    this.setState({
      isLoading: true,
      popoverOpen: false,
      isError: false
    })

    let url = id ? `/api/v1/paciente/${id}` : `/api/v1/paciente`;
    let method  = id ? 'PUT' : 'POST';

    const { nome, sexo, data_nascimento, cpf, rg, profissao, pai, mae, telefone, religiao, responsavel, 
          email, informacaoes, endereco, numero, bairro, complemento, cep, cidade } = this.state;
    this._request = request(url, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nome: nome,
            sexo: sexo,
            data_nascimento: data_nascimento.substring(0,10),
            cpf: cpf,
            rg: rg,
            profissao: profissao,
            pai: pai,
            mae: mae,
            telefone: telefone,
            religiao: religiao,
            responsavel: responsavel,
            email: email,
            informacaoes: informacaoes,
            endereco: endereco,
            numero: numero,
            bairro: bairro,
            complemento: complemento,
            cep: cep,
            cidade: cidade
        })
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        id: data.id,
        nome: data.nome,
        sexo: data.sexo,
        data_nascimento: data.data_nascimento.substring(0,10),
        cpf: data.cpf,
        rg: data.rg,
        profissao: data.profissao,
        pai: data.pai,
        mae: data.mae,
        telefone: data.telefone,
        religiao: data.religiao,
        responsavel: data.responsavel,
        email: data.email,
        informacaoes: data.informacaoes,
        endereco: data.endereco,
        numero: data.numero,
        bairro: data.bairro,
        complemento: data.complemento,
        cep: data.cep,
        cidade: data.cidade,
        created_by: data.created_by,
        created_date: data.created_date,
        last_modified_by: data.last_modified_by,
        last_modified_date: data.last_modified_date,
        isLoading: false,
        popoverOpen: true,
        popoverTitle: 'Sucesso',
        popoverMessage: 'Seus dados foram salvo com sucesso.',
			});
    })
    .catch(err =>
      this.setState({
        popoverOpen: true,
        popoverTitle: 'Erro',
        popoverMessage: 'Ocorreu um erro ao salvar os seus dados.',
        isLoading: false,
        isError: true
      })
		);
  }
  
  handleBack(){
    const query = new URLSearchParams(this.props.location.search);
		const page = query.get('page');
    this.props.history.push(`/paciente?page=${page}`);
  }

  onDismiss() {
    this.setState({
      popoverOpen: false
    });
  }

  handleLoad = async () => {
    const { id } = this.props.match.params
    if(!id) return;
    
    const { isLoading } = this.state;
    if(isLoading)
      return;

    this.setState({
      id: id,
      isLoading: true,
      isError: false
    })
		
    this._request = request(`/api/v1/paciente/${id}`)
    .then(response => response.json())
		.then(data => {
			this.setState({
        id: data.id,
        nome: data.nome,
        sexo: data.sexo,
        data_nascimento: data.data_nascimento.substring(0,10),
        cpf: data.cpf,
        rg: data.rg,
        profissao: data.profissao,
        pai: data.pai,
        mae: data.mae,
        telefone: data.telefone,
        religiao: data.religiao,
        responsavel: data.responsavel,
        email: data.email,
        informacaoes: data.informacaoes,
        endereco: data.endereco,
        numero: data.numero,
        bairro: data.bairro,
        complemento: data.complemento,
        cep: data.cep,
        cidade: data.cidade,
        created_by: data.created_by,
        created_date: data.created_date,
        last_modified_by: data.last_modified_by,
        last_modified_date: data.last_modified_date,
				isLoading: false,
			});
		})
		.catch(err =>
			this.setState({ isError: true, isLoading: false })
		);
  }

  async handleCidade(inputValue) {
    if (!inputValue) {
        return [];
    }
    const response = await request(
        `/api/v1/cidade?nome=${inputValue}&size=10`
    );
    const json = await response.json();
    return json.content;
  }

  handleCidadeChange = cidade => {
    this.setState({ cidade: cidade });
  };

  handleSexo = async () => {
		this._request = request(`/api/v1/sexo`)
		.then(response => response.json())
		.then(data => {
			this.setState({
				sexos: data
			});
		});
	}

  componentDidMount() {
    this.handleLoad();
    this.handleSexo();
	}

  render() {
    const { isLoading, id, nome, rg, cpf, sexo, data_nascimento, profissao, pai, mae, religiao, responsavel, email, informacaoes, endereco, numero, bairro, complemento, cidade, sexos, created_by, created_date, last_modified_by, last_modified_date, isError } = this.state;
   
    return (
      <div>
        <Breadcrumb>
            <BreadcrumbItem><a href="#">Cadastro</a></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/paciente`}>Paciente</Link></BreadcrumbItem>
            {nome && (
              <BreadcrumbItem active>{nome}</BreadcrumbItem>
            )}
        </Breadcrumb>
        <Card>
          <CardBody>
            <div className="d-flex align-items-center">
              <div>
                <CardTitle>Dados do conteúdo</CardTitle>
              </div>
            </div>
            <Form method="POST" autoComplete="off" onSubmit={this.handleSubmit} >
                <Col>
                  <FormGroup>
                    <Label for="full_name">Nome </Label> <Label className="text-danger">*</Label>
                    <Input type="text" name="nome" id="nome" value={nome} required={true} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Row form className="ml-2">
                  <Col xs="auto" sm="3" lg="2">
                    <FormGroup>
                      <Label for="sexo">Sexo </Label> <Label className="text-danger">*</Label>
                      <Input type="select" name="sexo" id="sexo" value={sexo} required={true} onChange={this.handleInputChange} disabled={isLoading}>
                      {sexos.map(row => {
                        return (
                          <option value={row.valor}>{row.nome}</option>
                        );
                      })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="auto" sm="3" lg="3">
                    <FormGroup>
                      <Label for="data_nascimento">Nascimento </Label> <Label className="text-danger">*</Label>
                      <Input type="date" name="data_nascimento" id="data_nascimento" value={data_nascimento} required={true} onChange={this.handleInputChange} disabled={isLoading}></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form className="ml-2">
                  <Col sm="3">
                    <FormGroup>
                      <Label for="rg">RG </Label>
                      <Input type="text" name="rg" id="tg" value={rg} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <Label for="cpf">CPF </Label>
                      <Input type="text" name="cpf" id="cpf" value={cpf} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Col>
                  <FormGroup>
                    <Label for="profissao">Profissõo </Label>
                    <Input type="text" name="profissao" id="profissao" value={profissao} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Row form className="ml-2">
                  <Col>
                    <FormGroup>
                      <Label for="pai">Pai </Label>
                      <Input type="text" name="pai" id="pai" value={pai} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                    </FormGroup>
                  </Col>
                  <Col className="mr-3">
                    <FormGroup>
                      <Label for="mae">Mãe </Label>
                      <Input type="text" name="mae" id="mae" value={mae} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Col>
                  <FormGroup>
                    <Label for="religiao">Religião </Label>
                    <Input type="text" name="religiao" id="religiao" value={religiao} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="responsavel">Responsável </Label>
                    <Input type="text" name="responsavel" id="responsavel" value={responsavel} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="email">E-mail </Label>
                    <Input type="email" name="email" id="email" value={email} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="informacaoes">Informações </Label>
                    <Input type="text" name="informacaoes" id="informacaoes" value={informacaoes} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Row form className="ml-2">
                  <Col>
                    <FormGroup>
                      <Label for="endereco">Endereço </Label>
                      <Input type="text" name="endereco" id="endereco" value={endereco} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                    </FormGroup>
                  </Col>
                  <Col md="2" className="mr-3">
                    <FormGroup>
                      <Label for="numero">Número </Label>
                      <Input type="text" name="numero" id="numero" value={numero} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Col>
                  <FormGroup>
                    <Label for="bairro">Bairro </Label>
                    <Input type="text" name="bairro" id="bairro" value={bairro} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="complemento">Complemento </Label>
                    <Input type="text" name="complemento" id="complemento" value={complemento} required={false} onChange={this.handleInputChange} disabled={isLoading}/>
                  </FormGroup>
                </Col>
                <Col>
									<FormGroup>
										<Label for="cidade">Cidade </Label> <Label className="text-danger">*</Label>
										<AsyncSelect
											value={cidade} 
											loadOptions={this.handleCidade}
											getOptionValue={(option) => option.id}
											getOptionLabel={(option) => `${option.nome} - ${option.uf}`}
											noOptionsMessage={(value) => (value.inputValue.length > 0 ?`Não encontrada: ${value.inputValue}` : 'Digite o nome da cidade')}
											placeholder={''}
											onChange={this.handleCidadeChange}
                      required={true}
                      name="cidade"
										/>
									</FormGroup>
								</Col>
                <Row form>
                  <FormGroup>
                    <Button type="button" color="light" onClick={this.handleBack} className="ml-3 mb-2 mt-2">
                      Voltar
                    </Button>
                  </FormGroup>
                  <FormGroup>
                    <Button id="save" type="submit" disabled={isLoading} color="success" className="ml-3 mb-2 mt-2">
                      Salvar alterações
                    </Button>
                  </FormGroup>
                </Row>
              </Form>
              <Alert color={isError ? "danger" : "success"} isOpen={this.state.popoverOpen} toggle={this.onDismiss}>
                {this.state.popoverMessage}
              </Alert>
              {isLoading && (
                <div style={{textAlign: 'center'}}>
                  <div style={{textAlign: 'center'}}> 
                    <Spinner type="grow" color="primary" />
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="success" />
                    <Spinner type="grow" color="danger" />
                    <Spinner type="grow" color="warning" />
                    <Spinner type="grow" color="info" />
                    <Spinner type="grow" color="dark" />
                  </div>
                  Carregando...
                </div>
              )}
          </CardBody>
          {id && (
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="text-muted">
                  <CardTitle>Dados da auditoria</CardTitle>
                </div>
              </div>
              <div className="text-muted">
                <Label>Criado por: {created_by} -  {new Date(created_date).toUTCString()}</Label>
              </div>
              <div className="text-muted">
                <Label>Atualizado por: {last_modified_by} - {new Date(last_modified_date).toUTCString()}</Label>
              </div>
            </CardBody>
          )}
        </Card>
      </div>
    );
  }
}
export default PacienteForm;
