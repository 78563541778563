import React from 'react';
import {
	Nav,
	Navbar,
	NavbarBrand,
	Collapse,
	Button,
	UncontrolledDropdown,
	DropdownToggle
} from 'reactstrap';
import { createHashHistory } from 'history'
import { logout } from "../../services/Auth";
import request from "services/ApiService";

import logodarkicon from '../../assets/images/logo-icon.png';
import logolighticon from '../../assets/images/logo-light-icon.png';
import logodarktext from '../../assets/images/logo-text.png';
import logolighttext from '../../assets/images/logo-light-text.png';
import logo from '../../assets/images/logo.png';

const history = createHashHistory()

class Header extends React.Component {

	_request = null;
	
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleInfo = this.handleInfo.bind(this);

		this.state = {
			isOpen: false,
			full_name: '',
		};
	}

	handleInfo = async () => {
		this._request = request(`/api/v1/autenticacao/account-info`)
		.then(response => response.json())
			.then(data => {
				this.setState({
					full_name: data.usuario
				});
			});
	}

	handleLogout = async () => {
        logout();
        history.push('/');
	}
	
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}

	componentDidMount() {
		this.handleInfo();
	}

	render() {
		const { full_name } = this.state;
		return (
			<header className="topbar navbarbg" data-navbarbg="skin6">
				<Navbar className="top-navbar" dark expand="md">
					<div className="navbar-header" id="logobg" data-logobg="skin5">
						<NavbarBrand href="/">
							<span className="logo-text">
								<img
									src={logo}
									className="light-logo"
									alt="homepage"
								/>
							</span>
						</NavbarBrand>
						<a className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
							<i className="ti-menu ti-close" />
						</a>
					</div>
					<Collapse className="navbarbg" isOpen={this.state.isOpen} navbar data-navbarbg="skin5" >
						<Nav className="ml-auto float-right" navbar>
							<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret className="pro-pic">
									<span className="text-dark">{full_name}</span>
									<Button
										onClick={() => this.handleLogout()}
										color="secondary"
										className="btn-rounded ml-3 mb-2 mt-2 ml-3" >
										Sair
									</Button>
								</DropdownToggle>
							</UncontrolledDropdown>
						</Nav>
					</Collapse>
				</Navbar>
			</header>
		);
	}
}

export default Header;
