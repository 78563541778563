import MedidaList from '../views/medida/list.jsx';
import MedidaForm from '../views/medida/form.jsx';

import ExameList from '../views/exame/list.jsx';
import ExameForm from '../views/exame/form.jsx';

import ProfissionalList from '../views/profissional/list.jsx';
import ProfissionalForm from '../views/profissional/form.jsx';

import FormulaList from '../views/formula/list.jsx';
import FormulaComponenteList from '../views/formula/componente.jsx';
import FormulaForm from '../views/formula/form.jsx';

import PacienteList from '../views/paciente/list.jsx';
import PacienteForm from '../views/paciente/form.jsx';
import PacienteExameList from '../views/paciente/exame.jsx';

import AtendimentoList from '../views/atendimento/list.jsx';
import AtendimentoForm from '../views/atendimento/form.jsx';
import AtendimentoExameList from '../views/atendimento/exame.jsx';

import ComponenteList from '../views/componente/list.jsx';
import ComponenteForm from '../views/componente/form.jsx';

import UsuarioList from '../views/usuario/list.jsx';
import UsuarioForm from '../views/usuario/form.jsx';

var ThemeRoutes = [
  {
    path: '/atendimento',
    name: 'Atendimento',
    icon: 'fa fa-band-aid',
    component: AtendimentoList
  },
  {
    path: '/atendimento-add',
    component: AtendimentoForm
  },
  {
    path: '/atendimento-edit/:id',
    component: AtendimentoForm
  },
  {
    path: '/atendimento-exame/:id',
    component: AtendimentoExameList
  },
  {
    path: '/medida',
    name: 'Unidade de medida',
    icon: 'fa fa-balance-scale',
    component: MedidaList
  },
  {
    path: '/medida-add',
    component: MedidaForm
  },
  {
    path: '/medida-edit/:id',
    component: MedidaForm
  },
  {
    path: '/exame',
    name: 'Exame',
    icon: 'fa fa-medkit',
    component: ExameList
  },
  {
    path: '/exame-add',
    component: ExameForm
  },
  {
    path: '/exame-edit/:id',
    component: ExameForm
  },
  {
    path: '/profissional',
    name: 'Profissional',
    icon: 'fa fa-user-md',
    component: ProfissionalList
  },
  {
    path: '/profissional-add',
    component: ProfissionalForm
  },
  {
    path: '/profissional-edit/:id',
    component: ProfissionalForm
  },
  {
    path: '/formula',
    name: 'Formula',
    icon: 'fa fa-book',
    component: FormulaList
  },
  {
    path: '/formula-add',
    component: FormulaForm
  },
  {
    path: '/formula-edit/:id',
    component: FormulaForm
  },
  {
    path: '/formula-componente/:id',
    component: FormulaComponenteList
  },
  {
    path: '/paciente',
    name: 'Paciente',
    icon: 'fa fa-user',
    component: PacienteList
  },
  {
    path: '/paciente-add',
    component: PacienteForm
  },
  {
    path: '/paciente-edit/:id',
    component: PacienteForm
  },
  {
    path: '/paciente-exame/:id',
    component: PacienteExameList
  },
  {
    path: '/componente',
    name: 'Componente',
    icon: 'fa fa-tint',
    component: ComponenteList
  },
  {
    path: '/componente-add',
    component: ComponenteForm
  },
  {
    path: '/componente-edit/:id',
    component: ComponenteForm
  },
  {
    path: '/usuario',
    name: 'Usuário',
    icon: 'fa fa-users',
    component: UsuarioList
  },
  {
    path: '/usuario-add',
    component: UsuarioForm
  },
  {
    path: '/usuario-edit/:id',
    component: UsuarioForm
  },
  { path: '/', pathTo: '/atendimento', name: 'Dashboard', redirect: true }
];
export default ThemeRoutes;
