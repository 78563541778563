import React from 'react';
import {
	Card,
	CardBody,
	CardTitle,
	Button,
    Form,
    FormGroup,
    Input,
    Alert,
    Col,
    Row,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap';
import request from "../../services/ApiService";
import logodarkicon from '../../assets/images/logo-icon.png';
import { logout } from "../../services/Auth";

class PasswordChange extends React.Component {

    _request = null;

    constructor(props) {
		super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            password: '',
            password_confirm: '',
            isError: false
        }
    }
    
    handleSubmit = async (event) => {
        event.preventDefault();

        const { isLoading } = this.state;
        if(isLoading)
            return;
        
        this.setState({
            isLoading: true,
            isError: false
        })
        const query = new URLSearchParams(this.props.location.search);
        const activationKey = query.get('activationKey');
        const username = query.get('username');
        
        const { password } = this.state;
        this._request = request('/api/v1/authentication/reset-password', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_name: username,
                token: activationKey,
                password: password
            })
        })
        .then(response => response.json())
        .then(data => {
            logout();
            this.props.history.push('/');
        })
        .catch(err =>
          this.setState({
            isLoading: false,
            isError: true
          })
        );
    }

  render() {
    const { isError } = this.state;
    return (
        <div className="main-wrapper">
            <Row className="justify-content-center mt-5">
                <Col lg="5" sm="7" xl="4">
                    <Card>
                        <CardBody>
                            <CardTitle className="justify-content-center text-center mb-3">
                                <img src={logodarkicon} className="mb-2" alt="Merck"/>
                                <h5 >Recuperação se senha</h5>
                            </CardTitle>
                            <Row className="mt-4">
                                <Col lg="12">
                                    <Form method="POST" autoComplete="off" onSubmit={this.handleSubmit} >
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <Button><i className="ti-pencil"></i></Button>
                                                </InputGroupAddon>
                                                <Input bsSize="lg" type="password" placeholder="Nova senha" name="password" onChange={({target}) => this.setState({password: target.value})} required/>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <Button><i className="ti-pencil"></i></Button>
                                                </InputGroupAddon>
                                                <Input bsSize="lg" type="password" placeholder="Confirmar senha" name="password" onChange={({target}) => this.setState({password_confirm: target.value})} required/>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="col-xs-12 pb-3">
                                                <Button color="success" type="submit">Confirmar</Button>
                                            </div>
                                            {isError && (
                                                <Alert color="danger">
                                                    Hmm, deve ter ocorrido algum problema. Por favor, tente novamente.
                                                </Alert>
                                            )}
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row> 
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
  }
}
export default PasswordChange;
