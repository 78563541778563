import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import indexRoutes from './routes/index.jsx';
import { HashRouter } from 'react-router-dom'
import { isAuthenticated } from "./services/Auth";
import Login from './views/authentication/Login';
import PasswordChange from './views/authentication/PasswordChange';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.css';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <HashRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/reset-password" component={PasswordChange} />
      <Route path="/signup" component={() => <h1>SignUp</h1>} />
      {indexRoutes.map((prop, key) => {
        return <PrivateRoute path={prop.path} key={key} component={prop.component} />;
      })}
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </HashRouter>
);

export default Routes;