import React from "react";
import {
	Spinner,
	Card,
	CardBody,
	CardTitle,
	Button,
	Table,
	Alert,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	UncontrolledTooltip,
	Breadcrumb, 
	BreadcrumbItem,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import request from "services/ApiService";
import PaginationComponent from "../../components/pagination";
import update from 'immutability-helper';

class UsuarioList extends React.Component {

	_request = null;

	constructor(props) {
		super(props);
		this.handleLoad = this.handleLoad.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleClearSearch = this.handleClearSearch.bind(this);
		this.handleDelete = this.handleDelete.bind(this);

		this.state = {
            isLoading: false,
			isSearch: false,
			isDelete: false,
			isError: false,
            text: '',
            rows: [],
            pageNumber: 1,
            totalElements: 0,
            size: 6
        }
	}

	handleLoad = async (pageNumber = this.state.pageNumber) => {
		const { isLoading, size, text } = this.state;
        if(isLoading)
            return;

        await this.setState({
			isSearch: false,
			isLoading: true,
			isDelete: false,
			isError: false
        })

        if(text.length > 0) {
            this.setState({
                isSearch: true
            })
		}
		
		this.props.history.push(`?page=${pageNumber}`);
		
		this._request = request(`/api/v1/usuario?nome=${text}&page=${pageNumber}&size=${size}&sort=nome,asc`)
		.then(response => response.json())
		.then(data => {
			if(data.content.length === 0 && 
				pageNumber > 1) {
					this.handleClearSearch();
			}
			this.setState({
				pageNumber: pageNumber++,
				rows: data.content,
				totalElements: data.totalElements,
				isLoading: false
			});
		})
		.catch(err =>
			this.setState({ isError: true, isLoading: false })
		);
	}

	handleSearch = async () => {
		this.handleLoad(1);
	}

	handleClearSearch = async () => {
		await this.setState({
			text: '',
			pageNumber: 1,
			isSearch: false
		})
		this.handleLoad(1);
	}

	handleDelete = async () => {
		const { selected } = this.state;
        if(!selected) return;

		this._request = request(`/api/v1/usuario/${selected.id}`, {
			method: 'DELETE'
		})
		.then(() => {
		  this.handleLoad();
		});
	}

	componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		const page = query.get('page');
		this.handleLoad(page || 1);
	}

	render() {

		const { isLoading, rows, error, totalElements, pageNumber, size, text, isSearch, selected } = this.state;

		return (
			<div>
				<Breadcrumb>
					<BreadcrumbItem><a href="#">Cadastro</a></BreadcrumbItem>
					<BreadcrumbItem active>Usuário</BreadcrumbItem>
				</Breadcrumb>
				<Card>
					<CardBody>
						<div className="d-flex align-items-center">
							<div>
								<CardTitle>Lista de usuários</CardTitle>
							</div>
							<div className="ml-auto d-flex no-block align-items-center">
								<div className="dl">
									<Button color="light" onClick={() => this.handleLoad()} className="ml-3 mb-2 mt-2">
										Recarregar
									</Button>
									<Link to={`/usuario-add`}>
										<Button color="secondary" className="ml-3 mb-2 mt-2">
											Adicionar
										</Button>
									</Link>
								</div>
							</div>
						</div>
						<FormGroup className="mt-2">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<Button onClick={() => this.handleSearch()}><i className="fa fa-search"></i></Button>
								</InputGroupAddon>
								<Input autoComplete="off" type="search" name="title" id="title" placeholder="Busca" value={text} onKeyDown={(event) => event.key === 'Enter' ? this.handleSearch(): null} onChange={({target}) => this.setState({text: target.value})} disabled={isLoading}/>
								{isSearch && (
									<InputGroupAddon addonType="prepend">
										<Button id="btnClearSearch" color="danger" onClick={() => this.handleClearSearch()}><i className="fa fa-minus"></i></Button>
										<UncontrolledTooltip placement="left" target="btnClearSearch">
											Limpar filtro de busca
										</UncontrolledTooltip>
									</InputGroupAddon>
								)}
							</InputGroup>
						</FormGroup>
						{isLoading && (
							<div style={{textAlign: 'center'}}>
								<div style={{textAlign: 'center'}}> 
									<Spinner type="grow" color="primary" />
									<Spinner type="grow" color="secondary" />
									<Spinner type="grow" color="success" />
									<Spinner type="grow" color="danger" />
									<Spinner type="grow" color="warning" />
									<Spinner type="grow" color="info" />
									<Spinner type="grow" color="dark" />
								</div>
								Carregando...
							</div>
						)}
						{!isLoading && error && (
							<Alert color = "danger" style={{textAlign: 'center'}}>
								Ocorreu um erro ao localizar os dados. Clique aqui para tentar mais uma vez.
							</Alert>
						)}
						{ !isLoading && rows.length > 0 && (
							<Table className="no-wrap v-middle" responsive hover striped>
								<tbody>
									{rows.map(row => {
										return (
											<tr key={row.id}>
												<td>
													<div className="d-flex no-block align-items-center">
														<div>
															<h5 className="mb-0 font-16 font-medium"><Link to={`/usuario-edit/${row.id}?page=${pageNumber}`}>{row.nome}</Link></h5>
															<span className="text-muted">{row.perfil}</span>
														</div>
													</div>
												</td>
												<td>
													<div>
														<span><span className="text-muted">Profissional:</span> {row.profissional ? row.profissional.nome : 'Não definido'}</span>
													</div>
												</td>
												<td style={{width: 10}}>
													<InputGroupAddon addonType="prepend">
														<Button size="sm" id={`btnDelete_${row.id}`} color="danger" onClick={() => this.setState({ isDelete: true, selected: row })}><i className="fa fa-times"></i></Button>
														<UncontrolledTooltip placement="bottom" target={`btnDelete_${row.id}`}>
															Apagar registro
														</UncontrolledTooltip>
													</InputGroupAddon>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						)}
						<Modal isOpen={this.state.isDelete} centered={true} fade={false}>
							<ModalHeader>Exclusão de dados</ModalHeader>
							<ModalBody>
								<b>Nome: </b> {selected ? selected.nome : ""}<br />
								<b>Criado por: </b> {selected ? selected.created_by: ""} - {selected ? new Date(selected.created_date).toUTCString(): ""}<br />
							</ModalBody>
							<ModalFooter>
								<Button color="danger" onClick={() => this.handleDelete()}>Excluir</Button>{' '}
								<Button color="secondary" onClick={() => this.setState({ isDelete: false })}>Cancelar</Button>
							</ModalFooter>
						</Modal>
							<PaginationComponent
								style={{ display: !isLoading && rows.length > 0 ? 'block' : 'none' }}
								totalItems={totalElements}
								pageSize={size}
								onSelect={this.handleLoad}
								maxPaginationNumbers={9}
								activePage={pageNumber}
							/>
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default UsuarioList;
